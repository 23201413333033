import Button from "@components/Button";
import CheckboxInputButton from "@components/CheckboxInputButton";
import LayoutScrollable from "@components/LayoutScrollable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function TopicsPage() {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const { t } = useTranslation();

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const topic = event.target.value;

    if (checked) {
      setSelectedTopics([...selectedTopics, topic]);
    } else {
      setSelectedTopics(
        selectedTopics.filter((selectedTopic) => selectedTopic !== topic),
      );
    }
  };

  return (
    <LayoutScrollable>
      <div className="bg-custom-black-transparent sticky top-0 shrink-0 py-8">
        <h1 className="heading">{t("pages.onboarding.topics.heading")}</h1>
      </div>

      <Form className="flex grow flex-col" method="post">
        <div className="flex grow flex-col justify-center py-8">
          <div className="grid grid-cols-2 gap-5 pb-36">
            <CheckboxInputButton
              className=""
              emoji="💸️"
              id="earning-opportunities"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.earningOpportunities")}
            />

            <CheckboxInputButton
              className=""
              emoji="🗿"
              id="history-secrets"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.historySecrets")}
            />

            <CheckboxInputButton
              className=""
              emoji="🗣️"
              id="multiple-languages"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.multipleLanguages")}
            />

            <CheckboxInputButton
              className=""
              emoji="🎨"
              id="artistic-expression"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.artisticExpression")}
            />

            <CheckboxInputButton
              className=""
              emoji="🗺️"
              id="travel-adventures"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.travelAdventures")}
            />

            <CheckboxInputButton
              className=""
              emoji="📈️"
              id="stock-market"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.stockMarket")}
            />

            <CheckboxInputButton
              className=""
              emoji="🧪️"
              id="science-mysteries"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.scienceMysteries")}
            />

            <CheckboxInputButton
              className=""
              emoji="🗳️"
              id="political-landscape"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.politicalLandscape")}
            />

            <CheckboxInputButton
              className=""
              emoji="🤔️️️"
              id="philosophical-ideas"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.philosophicalIdeas")}
            />

            <CheckboxInputButton
              className=""
              emoji="🧠️"
              id="human-behavior"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.topics.humanBehavior")}
            />
          </div>
        </div>

        <div className="sticky bottom-14 shrink-0 bg-transparent">
          <Button
            disabled={selectedTopics.length === 0}
            title={t("shared.continue")}
            type="submit"
          />
        </div>
      </Form>
    </LayoutScrollable>
  );
}
