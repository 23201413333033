import Button from "@components/Button";
import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import { ThumbsDownOrUp } from "src/types";

export default function ComplexConceptsPage() {
  const [selected, setSelected] = useState<ThumbsDownOrUp | null>(null);

  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.value;

    setSelected(selected as ThumbsDownOrUp);
  };

  const InitialContent = () => {
    return (
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">{t("pages.onboarding.complex.heading")}</h1>
        </div>

        <div className="flex grow flex-col items-center justify-center space-y-5">
          <div className="grid w-full grid-cols-2 gap-5">
            <RadioInputButton
              className=""
              emoji="👎"
              onChange={handleChange}
              name="learning-opportunities"
              value="thumbsDown"
            />

            <RadioInputButton
              className=""
              emoji="👍"
              onChange={handleChange}
              name="learning-opportunities"
              value="thumbsUp"
            />
          </div>
        </div>
      </div>
    );
  };

  const ThumbsDownContent = () => {
    return (
      <div className="flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center">
          <span className="text-7xl">💪</span>

          <h1 className="heading my-5">
            {t("pages.onboarding.complex.headingThumbsDown")}
          </h1>

          <p className="text-balance text-lg">
            {t("pages.onboarding.complex.textThumbsDown")}
          </p>
        </div>

        <div className="shrink-0">
          <Form method="post">
            <input name="complex-concepts" type="hidden" value="thumbsDown" />

            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      </div>
    );
  };

  const ThumbsUpContent = () => {
    return (
      <div className="flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center">
          <span className="text-7xl">🙏</span>

          <h1 className="heading my-5">
            {t("pages.onboarding.complex.headingThumbsUp")}
          </h1>

          <p className="text-balance text-lg">
            {t("pages.onboarding.complex.textThumbsUp")}
          </p>
        </div>

        <div className="shrink-0">
          <Form method="post">
            <input name="complex-concepts" type="hidden" value="thumbsUp" />

            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      </div>
    );
  };

  const Content = () => {
    switch (selected) {
      case "thumbsDown":
        return <ThumbsDownContent />;
      case "thumbsUp":
        return <ThumbsUpContent />;
      default:
        return <InitialContent />;
    }
  };

  return (
    <Layout>
      <Content />
    </Layout>
  );
}
