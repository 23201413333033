export default function CheckboxInputButton({
  className,
  emoji,
  id,
  name,
  onChange,
  title,
}: {
  className: string;
  emoji: string;
  id: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
}) {
  return (
    <div className="flex">
      <input
        className="peer hidden"
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={id}
      />

      <label
        className={`${className} button-input bg-transparent peer-checked:bg-primary-green-dark`}
        htmlFor={id}
      >
        {emoji}
        <br />
        {title}
      </label>
    </div>
  );
}
